@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

body {
    font-family: 'Source Sans Pro', sans-serif;
}

/* util class  */
.active-btn {
    @apply bg-black text-white
}

.signin-btn {
    @apply text-white bg-green-500 hover:bg-green-400 mr-4
}

.signin-form {
    @apply shadow-lg border p-4 w-[450px] mx-auto
}

.google-signin {
    @apply flex flex-wrap items-center justify-center w-[450px] mx-auto border shadow-lg py-1
}

.forgot-password {
    @apply text-blue-500 italic text-sm hover:text-blue-600 hover:underline
}

/* //// */

.nav {
    height: 60px;
}

.nav svg {
    @apply mx-3 my-2 cursor-pointer text-xl
}

.nav svg:hover {
    @apply hover:animate-pulse
}

.sidebar-btns h1 {
    @apply cursor-pointer p-4 shadow-md
}

.content {
    @apply flex flex-wrap items-center m-4;
    /* height: calc(100vh - 60px) !important; */
}

table th, td {
    @apply border border-slate-300
}

.btn {
    @apply font-bold rounded-md py-2 px-4 text-white cursor-pointer
}

form label {
    @apply block font-bold mb-2
}

form input {
    @apply shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none ;
}

.create-account-btn {
    @apply border-2 border-slate-500 text-slate-600 hover:bg-slate-500 hover:text-slate-50
}